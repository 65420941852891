<script setup>
const supabase = useSupabaseClient()
const { locale, setLocale } = useI18n()
const currentLocale = computed(() => locale.value)
const winnerSearch = ref('')
// Define the leaderboard data
const leaderboardData = ref([]);
const leaderboard = computed(() => leaderboardData.value);
// const sortedLeaderboard = computed(() => {
//     return leaderboard.value.slice().sort((a, b) => a.appSettings.game.bestTime - b.appSettings.game.bestTime).slice(0, 10);
// });
onMounted(() => {
    getLeaderboard()
    liveUpdate()
})
onUnmounted(() => {
    if (subscription) {
        subscription.unsubscribe(); // Correctly unsubscribe
    }
})

const getLeaderboard = async () => {
    try {
        const { data, error } = await supabase
            .from('leaderboard')
            .select()
            .order('total_score', { ascending: false });

        if (error) {
            console.log(error);
        } else {
            // console.log(data.slice(0, 10));
            leaderboardData.value = data
            // console.log(leaderboard.value);


        }

    } catch (error) {
        console.log(error);

    }
}

// Live update the Leaderboard
let subscription;
const liveUpdate = () => {
    subscription = supabase.channel('custom-all-channel')
        .on(
            'postgres_changes',
            { event: '*', schema: 'public', table: 'leaderboard' },
            (payload) => {
                // console.log('Change received!', payload)
                getLeaderboard()
            }
        )
        .subscribe()
}

// search winner
// Function to search for a winner by name
const filteredLeaderboard = computed(() => {
    if (!winnerSearch.value.trim()) return leaderboard.value; // Return all if search is empty
    return leaderboard.value.filter(entry =>
        entry.racer_name.toLowerCase().includes(winnerSearch.value.toLowerCase())
    );
});

</script>
<template>
    <div class="md:p-5">
        <v-card class="flex lg:w-[50%] md:m-5 md:p-5 mx-auto min-h-[20rem]" data-aos="fade-up" data-aos-duration="500">
            <v-locale-provider :rtl="currentLocale == 'ar' ? false : false">
                <div class="m-5 flex flex-col items-center">
                    <v-icon class="ma-2" size="45">mdi-trophy</v-icon>
                    <v-card-title class="pa-5a text-center">
                        <span class="headline font-weight-black sm:text-2xl md:text-3xl xl:text-4xl ap-3 inline-block">
                            <p class="text-[#ff0050] inline-block mr-1.5">{{ $t('leaderboard.title.part1') }}</p>
                            <p class="text-[#00f2ea] inline-block">{{ $t('leaderboard.title.part2') }}</p> {{
                                $t('leaderboard.title.main') }}
                        </span>
                    </v-card-title>
                </div>
            </v-locale-provider>
            <v-lazy>

                <v-table class=" mx-auto text-center am-2">
                    <thead>
                        <div class="search flex ama-1 w-full min-w-[16rem]">
                            <input ref="search" v-model="winnerSearch" type="text" name="search" id="search"
                                @input="winner" :placeholder="$t('leaderboard.search')"
                                class="bg-zinc-800 md:p-2 p-1.5 m-2 outline-nonea w-full rounded-sm md:text-lg text-base" />
                            <v-btn @click="winnerSearch = ''" :ripple="false" :elevation="0"
                                class="my-auto">clear</v-btn>
                        </div>
                    </thead>
                    <thead class="text-center">
                        <tr class="text-center">
                            <th class="font-weight-bold md:text-lg text-center bg-[#ff0050]/50">
                                <v-icon class="md:mr-1 mx-auto">mdi-flag-checkered</v-icon>{{ $t('leaderboard.winner')
                                }}
                            </th>
                            <th class="font-weight-bold md:text-lg text-center bg-[#00f2ea]/50"><v-icon
                                    class="md:mr-1 mx-auto">mdi-medal</v-icon>{{ $t('leaderboard.wins') }}
                            </th>
                            <!-- <th class="font-weight-bold text-lg text-center bg-[#ff0050]/50"><v-icon
                                    class="mr-1 mx-auto">mdi-numeric-1-box</v-icon>Place</th> -->
                            <th class="font-weight-bold md:text-lg text-center bg-[#ff0050]/50"><v-icon
                                    class="md:mr-1 mx-auto">mdi-numeric-2-box</v-icon>{{ $t('leaderboard.secondPlace')
                                }}</th>
                            <th class="font-weight-bold md:text-lg text-center bg-[#00f2ea]/50"><v-icon
                                    class="md:mr-1 mx-auto">mdi-numeric-3-box</v-icon>{{ $t('leaderboard.thirdPlace') }}
                            </th>
                            <th class="font-weight-bold md:text-lg text-center bg-[#ff0050]/50"><v-icon
                                    class="md:mr-1 mx-auto">mdi-bullseye-arrow</v-icon>{{ $t('leaderboard.score') }}
                            </th>

                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(entry, i) in filteredLeaderboard" :key="i" transition="dialog-bottom-transition"
                            class="even:bg-zinc-800/20 odd:bg-zinc-500/40">
                            <td class="data-cella md:text-lg">{{ entry.racer_name }}</td>
                            <td class="data-cella md:text-lg">{{ entry.wins }}</td>
                            <!-- <td class="data-cella md:text-lg">{{ entry.first_place }}</td> -->
                            <td class="data-cella md:text-lg">{{ entry.second_place }}</td>
                            <td class="data-cella md:text-lg">{{ entry.third_place }}</td>
                            <td class="data-cella md:text-lg">{{ entry.total_score }}</td>
                        </tr>
                    </tbody>
                    <div v-if="filteredLeaderboard.length == 0"
                        class="w-full min-w-[5rem] bg-zinc-950 text-xl font-semibold p-3">No
                        Results</div>
                </v-table>

            </v-lazy>
        </v-card>
    </div>
</template>
